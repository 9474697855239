@tailwind base;
@tailwind components;
@tailwind utilities;

body{
	@apply p-safe antialiased font-sans;

	/* PWA enhancements */
	-webkit-overflow-scrolling: touch;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}

html{
  -webkit-overflow-scrolling: touch;
}

#root{
	@media screen and (min-width: 940px) {
		/* border: 1px solid red; */
	/* width: 1920px;
	height: 1080px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: auto; */
	}
}

#modal {
	z-index: 9999;
}

input[type=text] {
	font-size: 16px !important;
}

.scroll-menu::-webkit-scrollbar{
	width: 0.5em;
	height: 0.5em;
	background-color: transparent;
  }
  
  .scroll-menu::-webkit-scrollbar-thumb {
	background-color:#dd2581;
  }
  
  .scroll-menu::-webkit-scrollbar-track {
	  background-color:rgba(0, 43, 83, 0.2);
	}

.bg-vortex {
	background-image: url('assets/img/bg-blue-vortex.jpg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}