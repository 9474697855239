#root {
  max-width: 1920px;
  margin: 0 auto;
}

.scroll-wrapper::-webkit-scrollbar {
  width: 0.25rem;
}

.scroll-wrapper::-webkit-scrollbar-track {
  background: rgb(0, 43, 83);
}

.scroll-wrapper::-webkit-scrollbar-thumb {
  background: rgb(246, 178, 5);
}

.modal-create-mission {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    cursor: pointer;
    border-radius: 4px;
    margin-right: 2px;
    filter: invert(1);
  }
}
