.box {
  @apply border border-brand-light-blue bg-brand-dark-blue bg-opacity-80 relative;

  &::before {
    @apply z-0 opacity-50 absolute inset-2 bg-gradient-to-b from-brand-light-blue to-transparent pointer-events-none;
    content: "";
    pointer-events: none;
  }

  // corner borders
  &::after {
    @apply z-0 absolute inset-0 pointer-events-none;
    background: url(../assets/img/box-bottom.svg) no-repeat bottom,
      url(../assets/img/box-top.svg) no-repeat top,
      linear-gradient(to bottom, #1b4578, transparent 40px) no-repeat left,
      linear-gradient(to top, #1b4578, transparent 40px) no-repeat left;
    background-size: 100% auto;
    content: "";
    pointer-events: none;
  }

  h2 {
    @apply text-center text-2xl font-bold text-white;
  }
}
