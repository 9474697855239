#home {
    background: url(/assets/img/bg-blue-vortex.jpg) no-repeat center;
    background-size: cover;
}


// set color var
:root {
    --swiper-pagination-bottom: 0px;
    --swiper-pagination-color: #ff8800;
    --swiper-pagination-bullet-inactive-color: #fff;
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-navigation-color: #fff;
    --swiper-navigation-size: 32px !important;
    --swiper-navigation-top-offset: 55%;
}

